import React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMsal } from "@azure/msal-react";

const NavBar = ({ isAuthenticated, user }) => {

    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutPopup({
          postLogoutRedirectUri: "/signin",
          mainWindowRedirectUri: "/signin",
        });
    };

    return (
        <AppBar position="static" style={{backgroundColor:'#F7F6F5', color: '#000080', display: 'flex', flexDirection: 'row'}}>
            <Container style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px'}}>
                <Typography style={{fontFamily: 'ui-serif', fontSize: '2rem'}}>ACADIA</Typography>
                <Typography style={{letterSpacing: '0.5em'}} variant="caption">HEALTHCARE</Typography>
            </Container>
            { isAuthenticated && <div style={{justifyContent: 'end', display: 'flex', alignItems: 'center', marginRight: '2rem', whiteSpace: 'nowrap'}}>
                <Tooltip title={user?.email}>
                    <Typography style={{ marginRight: '1rem'}}>{user?.displayName}</Typography>
                </Tooltip>
                <Tooltip title="Sign Out">
                    <ExitToAppIcon fontSize="large" onClick={handleLogout} />
                </Tooltip>
            </div> }
        </AppBar>
    );
}

export { NavBar };