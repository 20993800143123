import React, { Suspense, lazy } from 'react';
import { Switch, Route, useLocation, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const commonRoutes = [

];

const Router = (props) => {
  let appRoutes = props?.appRoutes || [] ;
  // if a web app has already defined any of the above routes replace them
  let routes = commonRoutes.filter((item) => !props.appRoutes.find((val) => val.path == item.path));

  const backgroundRoutes = props.appRoutes.filter(item => item.background);
  const regularRoutes = props.appRoutes.filter(item => !item.background);

  routes = [...routes, ...regularRoutes];

  const location = useLocation();
  //const background = location.state && location.state.background;

  return (
    <Suspense fallback={<CircularProgress color="inherit" size={20} /> }>
      <Routes>
        {routes.map((item, index) => {
          const RouteComponent = item.component
          if (item.isAuthRoute || item.path === '*') {
            return <Route key={index} exact path={item.path} element={<AuthRoute path={item.path} component={item.component} />} />
          }
          return <Route key={index} exact path={item.path} element={<RouteComponent />}> </Route>;
        })}


        <Route
          render={() => (
            <>
              <h4>Oops, this page does not exist.</h4>
            </>
          )}
        />


        {backgroundRoutes.map((item, index) => {
          return <Route key={index} exact path={item.path} element={<AuthRoute component={item.component} />} />
        })}
      </Routes>
    </Suspense>
  );
}

Router.propTypes = {
  appRoutes: PropTypes.array.isRequired,
};

export { Router };
