import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";

const AuthRoute = ({ path, component: Component }) => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated || path==="*") {
            navigate('/signin')
        }
    }, []);

    return (
        <Component />
    );
};

AuthRoute.propTypes = {
    component: PropTypes.object.isRequired,
};

export default AuthRoute;
