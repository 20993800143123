import React, { Component } from 'react';
import './footer.css';
import { Typography } from '@mui/material';

class Footer extends Component {
  render() {
    return (
      <footer className='footer-distributed'>
        <div className='footer-contact'>
            <Typography variant='caption' style={{color: '#000080'}}>© 2024 ACADIA™ All Rights Reserved</Typography>
        </div>
      </footer>
    );
  }
}

export {Footer};