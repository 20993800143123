import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: localStorage.getItem('login')==='yes' || false,
  userEmail: '',
  accessToken: null,
  isAuthenticated: false,
  readOnlyView: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      const { payload } = action
      localStorage.setItem('login','yes')
      localStorage.setItem('email',payload?.email)
      state.isLoggedIn = true;
      state.userEmail = payload?.email
      state.readOnlyView = false;
    },
    logout: (state) => {
      localStorage.setItem('login','no')
      localStorage.setItem('email','')
      state.isLoggedIn = false;
      state.userEmail = ''
      state.readOnlyView = false;
    },
    setToken: (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.isAuthenticated = true
      state.readOnlyView = action.payload.readOnlyView
    },
    clearToken: (state) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.isAuthenticated = false
      state.readOnlyView = false;
    }
  }
})

export const { login, logout, setToken, clearToken } = userSlice.actions

export default userSlice.reducer
